import PropTypes, { number } from 'prop-types'
import React from 'react'

import { AdDetailsBreadcrumbs } from '../AdDetailsBreadcrumbs'
import { AdListBreadcrumbs } from '../AdListBreadcrumbs'

export const Container = ({ isAdDetail, pageUrlItems }) => {
  if (isAdDetail) return <AdDetailsBreadcrumbs />
  return <AdListBreadcrumbs pageUrlItems={pageUrlItems} />
}

Container.propTypes = {
  isAdDetail: PropTypes.bool,
  pageUrlItems: PropTypes.object
}
