import PropTypes, { number } from 'prop-types'
import React from 'react'
import Link from 'next/link'

export const Item = ({ href, as, isLast, text }) => {
  return (
    <>
      <Link
        as={as}
        href={href}
      >
        <a className='ad-breadcrumbs__link'>
          {text}
        </a>
      </Link>

      {!isLast ? <span className='ad-breadcrumbs__separator--slash' /> : null}
    </>
  )
}

Item.propTypes = {
  as: PropTypes.string.isRequired,
  href: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.object
  }).isRequired,
  isLast: PropTypes.bool,
  text: PropTypes.string
}
