import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'

export const LastModifiedDate = ({date}) => (
  date
    ? <div className='oglasi-datum'>
      <i className='ico-datum ico' />&nbsp;
      <p>{moment(date).format('DD.MM.YYYY')}</p>
    </div>
    : null
)

LastModifiedDate.propTypes = {
  date: PropTypes.string
}

LastModifiedDate.defaultProps = {
  date: null
}
