import PropTypes, { number } from 'prop-types'
import React from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { connect } from 'react-redux'
import useTranslation from 'next-translate/useTranslation'
import { LastModifiedDate } from 'SRC/modules/ads/detail/components/LastModifiedDate'
import { fetchCitiesByCountry } from 'SRC/modules/geoObjects/cities/actions'
import { getCitiesByCountry } from 'SRC/modules/geoObjects/cities/selectors'
import { getCurrentAd } from 'SRC/modules/ads/detail/selectors'
import { getPageMetaTagsSingle, getIsMobileDevice } from 'SRC/modules/common/selectors'
import { globalOptions } from 'SRC/core/constants'
import { Item } from '../Item'
import countriesArr from '../../../../../../../public/data/countriesArr.json'

import translations from '../../../../../../../public/data/translations.json'

const AdDetailsBreadcrumbs = ({
  ad,
  pageMetaTagsSingle,
  isAdDetail,
  isMobileDevice,
  citiesByCountry,
  fetchCitiesByCountry
}) => {
  const { t, lang } = useTranslation('common')
  const router = useRouter()

  const locale = lang?.toUpperCase() || 'ME'

  const [citySeo, setCitySeo] = React.useState(ad?.city?.seo || '')

  React.useEffect(() => {
    if (!citySeo && ad?.country?.id && !citiesByCountry?.length) {
      fetchCitiesByCountry(ad.country.id)
    }

    if (!citySeo && ad?.country?.id && citiesByCountry?.length) {
      const findCity = citiesByCountry.find(city => Number(city.id) === Number(ad.city.id))
      if (findCity) setCitySeo(findCity.seo)
    }
  }, [citySeo, citiesByCountry?.length])

  if (!ad || !ad.category) return null

  const breadCrumbItems = [{ key: 'home', as: '/', href: { pathname: '/index' }, text: t('Početna'), isLast: false }]

  let countrySeo = `${translations.drzava[locale]}-crna-gora`

  if (ad.country && ad.country.id !== globalOptions.MontenegroId) {
    if (ad.country.seo) {
      countrySeo = `${translations.drzava[locale]}-${ad.country.seo}`
    } else {
      const countryRow = Array.isArray(countriesArr) ? countriesArr.find(row => row[1] === ad.country.id) : null
      if (countryRow) countrySeo = countryRow[0]
    }
  }

  let baseAs = `/${translations.oglasi[locale]}/${ad.category.seo}`
  let baseHref = { pathname: '/search', query: { category: ad.category.seo, '0': [''] } }

  if (lang !== 'me') baseHref.query.locale = lang

  breadCrumbItems.push({ key: 'category', as: baseAs, href: baseHref, text: ad.category.title, isLast: false })

  if (pageMetaTagsSingle && Array.isArray(pageMetaTagsSingle.urlSpecs) && Array.isArray(ad.adsSpecifications)) {
    pageMetaTagsSingle.urlSpecs.forEach(urlSpec => {
      const findAdSpec = ad.adsSpecifications.find(adSpec => adSpec?.specification?.id === urlSpec.id)
      if (findAdSpec && findAdSpec.value) {
        const specOptionsIndex = urlSpec.options.findIndex(urlSpecOption => urlSpecOption === findAdSpec.value)

        if (specOptionsIndex !== -1) {
          const urlSpecSeo = urlSpec.seo[specOptionsIndex]

          baseAs = `${baseAs}/${urlSpecSeo}`
          baseHref = { ...baseHref, query: { ...baseHref.query, '0': [`${baseHref.query['0'][0]}/${urlSpecSeo}/`] } }

          breadCrumbItems.push({
            key: `spec-${urlSpecSeo}`,
            as: baseAs,
            href: baseHref,
            text: urlSpec.title[specOptionsIndex],
            isLast: false
          })
        }
      }
    })
  }

  if (['BrandModel', 'BrandOnly'].includes(ad.category.requiredAdOptions)) {
    const brandAs = `${baseAs}/${translations.proizvodjac[locale]}-${ad.brand.seo}`
    const brandHref = { ...baseHref, query: { ...baseHref.query, '0': [`${baseHref.query['0'][0]}${translations.proizvodjac[locale]}-${ad.brand.seo}`] } }
    breadCrumbItems.push({ key: 'brand', as: brandAs, href: brandHref, text: ad.brand.name, isLast: false })

    if (ad.category.requiredAdOptions === 'BrandOnly') {
      const countryAs = `${brandAs}/${countrySeo}`
      const countryHref = {
        ...baseHref, query: { ...baseHref.query, '0': [`${brandHref.query['0'][0]}/${countrySeo}`] }
      }

      const cityAs = `${countryAs}/${translations.grad[locale]}-${citySeo}`
      const cityHref = {
        ...baseHref, query: { ...baseHref.query, '0': [`${countryHref.query['0'][0]}/${translations.grad[locale]}-${citySeo}`] }
      }

      breadCrumbItems.push({ key: 'country', as: countryAs, href: countryHref, text: ad.country.name, isLast: false })
      breadCrumbItems.push({ key: 'city', as: cityAs, href: cityHref, text: ad.city.name, isLast: true })
    }

    const modelSeo = ad.product?.seo || (ad.product?.model ? ad.product.model.toLowerCase().split(' ').join('-') : '') || ''

    if (ad.category.requiredAdOptions === 'BrandModel') {
      const modelAs = `${brandAs}/${translations.model[locale]}-${modelSeo}`
      const modelHref = {
        ...baseHref,
        query: { ...baseHref.query, '0': [`${brandHref.query['0'][0]}/${translations.model[locale]}-${modelSeo}`] }
      }

      const countryAs = `${modelAs}/${countrySeo}`
      const countryHref = {
        ...baseHref, query: { ...baseHref.query, '0': [`${modelHref.query['0'][0]}/${countrySeo}`] }
      }

      const cityAs = `${countryAs}/${translations.grad[locale]}-${citySeo}`
      const cityHref = {
        ...baseHref, query: { ...baseHref.query, '0': [`${countryHref.query['0'][0]}/${translations.grad[locale]}-${citySeo}`] }
      }

      breadCrumbItems.push({ key: 'model', as: modelAs, href: modelHref, text: ad.product.model, isLast: false })
      breadCrumbItems.push({ key: 'country', as: countryAs, href: countryHref, text: ad.country.name, isLast: false })
      breadCrumbItems.push({ key: 'city', as: cityAs, href: cityHref, text: ad.city.name, isLast: true })
    }
  }

  if (ad.category.requiredAdOptions === 'Services') {
    const serviceAs = `${baseAs}/${translations.usluga[locale]}-${ad.service.seo}`
    const serviceHref = { ...baseHref, query: { ...baseHref.query, '0': [`${baseHref.query['0'][0]}${translations.usluga[locale]}-${ad.service.seo}`] } }
    breadCrumbItems.push({ key: 'service', as: serviceAs, href: serviceHref, text: ad.service.name, isLast: false })

    const serviceTypeAs = `${serviceAs}/${translations['tip-usluge'][locale]}-${ad.serviceType.seo}`
    const serviceTypeHref = {
      ...baseHref,
      query: { ...baseHref.query, '0': [`${serviceHref.query['0'][0]}/${translations['tip-usluge'][locale]}-${ad.serviceType.seo}`] }
    }

    breadCrumbItems.push({ key: 'serviceType', as: serviceTypeAs, href: serviceTypeHref, text: ad.serviceType.name, isLast: false })

    const countryAs = `${serviceTypeAs}/${countrySeo}`
    const countryHref = {
      ...baseHref, query: { ...baseHref.query, '0': [`${serviceTypeHref.query['0'][0]}/${countrySeo}`] }
    }

    const cityAs = `${countryAs}/${translations.grad[locale]}-${citySeo}`
    const cityHref = {
      ...baseHref, query: { ...baseHref.query, '0': [`${countryHref.query['0'][0]}/${translations.grad[locale]}-${citySeo}`] }
    }

    breadCrumbItems.push({ key: 'country', as: countryAs, href: countryHref, text: ad.country.name, isLast: false })
    breadCrumbItems.push({ key: 'city', as: cityAs, href: cityHref, text: ad.city.name, isLast: true })
  }

  if (!ad.category.requiredAdOptions || ad.category.requiredAdOptions === null) {
    const countryAs = `${baseAs}/${countrySeo}`
    const countryHref = {
      ...baseHref, query: { ...baseHref.query, '0': [`${baseHref.query['0'][0]}/${countrySeo}`] }
    }

    const cityAs = `${countryAs}/${translations.grad[locale]}-${citySeo}`
    const cityHref = {
      ...baseHref, query: { ...baseHref.query, '0': [`${countryHref.query['0'][0]}/${translations.grad[locale]}-${citySeo}`] }
    }

    breadCrumbItems.push({ key: 'country', as: countryAs, href: countryHref, text: ad.country.name, isLast: false })
    breadCrumbItems.push({ key: 'city', as: cityAs, href: cityHref, text: ad.city.name, isLast: true })
  }

  return (
    <div className='ad-breadcrumbs'>
      <a href="javascript:void(0)" onClick={() => router.back()}>
        <i className='ico-nazad ico' />
        <p>{t('Nazad')}</p>
      </a>

      <span className='ad-breadcrumbs__separator--pipe' />

      {breadCrumbItems.map(breadCrumbItem => (
        <Item
          key={breadCrumbItem.key}
          as={breadCrumbItem.as}
          href={breadCrumbItem.href}
          text={breadCrumbItem.text}
          isLast={breadCrumbItem.isLast}
        />
      ))}

      <LastModifiedDate date={ad.activated} />
    </div>
  )
}

AdDetailsBreadcrumbs.propTypes = {
  ad: PropTypes.shape({
    activated: PropTypes.string,
    category: PropTypes.shape({
      requiredAdOptions: PropTypes.oneOf(['BrandModel', 'BrandOnly', 'Services']),
      title: PropTypes.string,
      seo: PropTypes.string,
    }),
    brand: PropTypes.shape({
      id: PropTypes.number,
      seo: PropTypes.string,
      name: PropTypes.string
    }),
    product: PropTypes.shape({
      id: PropTypes.number,
      seo: PropTypes.string,
      model: PropTypes.string
    }),
    country: PropTypes.shape({
      id: PropTypes.number,
      seo: PropTypes.string,
      name: PropTypes.string
    }),
    city: PropTypes.shape({
      id: PropTypes.number,
      seo: PropTypes.string,
      name: PropTypes.string
    }),
    service: PropTypes.shape({
      id: PropTypes.number,
      seo: PropTypes.string,
      name: PropTypes.string
    }),
    serviceType: PropTypes.shape({
      id: PropTypes.number,
      seo: PropTypes.string,
      name: PropTypes.string
    }),
  }).isRequired,
  pageMetaTagsSingle: PropTypes.shape({
    id: PropTypes.number.isRequired,
    urlSpecs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      seo: PropTypes.arrayOf(PropTypes.string),
      options: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.arrayOf(PropTypes.string)
    }))
  }),
  citiesByCountry: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    seo: PropTypes.string
  })),
  isMobileDevice: PropTypes.bool,
  fetchCitiesByCountry: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  const ad = getCurrentAd(state)

  return {
    ad,
    pageMetaTagsSingle: getPageMetaTagsSingle(state),
    isMobileDevice: getIsMobileDevice(state),
    citiesByCountry: getCitiesByCountry(state, ad?.country?.id)
  }
}

export default connect(mapStateToProps, { fetchCitiesByCountry })(AdDetailsBreadcrumbs)
